import AppBar from "@material-ui/core/AppBar"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import Drawer from "@material-ui/core/Drawer"
import IconButton from "@material-ui/core/IconButton"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Toolbar from "@material-ui/core/Toolbar"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import MenuIcon from "@material-ui/icons/Menu"
import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  logo: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
  },
  logoButton: {
    textTransform: "none",
    display: "flex",
    alignItems: "center",
  },
  logoFont: {
    fontSize: "1.5rem",
    fontWeight: 600,
    marginBottom: 0,
    letterSpacing: "-1px",
    color: "#102744",
  },
  title: {
    marginBottom: 0,
    marginLeft: "4px",
  },
  menuButton: {
    textTransform: "none",
  },
  menuIcon: {
    marginRight: theme.spacing(2),
  },
  list: {
    width: 250,
  },
}))

interface Props {
  siteTitle: string
}

export const Logo = () => {
  const classes = useStyles()
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo_institute.png" }) {
        childImageSharp {
          fluid(maxWidth: 32, maxHeight: 32) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div className={classes.logo}>
      <Button className={classes.logoButton} component={Link} to="/">
        <Img
          style={{ width: "32px", marginRight: "4px" }}
          fluid={data.placeholderImage.childImageSharp.fluid}
          alt="The Institute of Beauty Logo"
        />
        <p className={classes.logoFont}>The Institute of Beauty</p>
      </Button>
    </div>
  )
}

const Header = ({ siteTitle }: Props) => {
  const classes = useStyles()
  const theme = useTheme()
  const isNotMobile = useMediaQuery(theme.breakpoints.up("md"))
  const [showDrawer, setShowDrawer] = React.useState(false)

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer)
  }

  const menuItems = [
    { title: "About us", link: "/about-us/" },
    { title: "Courses", link: "/courses/" },
    { title: "Contact us", link: "/contact-us/" },
    { title: "Enrol", link: "/enrol/" },
  ]

  return (
    <AppBar position="sticky" color={"inherit"} elevation={0}>
      <Toolbar>
        {!isNotMobile ? (
          <>
            <Logo />
            <IconButton
              edge="start"
              className={classes.menuIcon}
              color="primary"
              aria-label="menu"
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
            <Drawer open={showDrawer} onClose={toggleDrawer} anchor="right">
              <div
                className={classes.list}
                role="presentation"
                onClick={toggleDrawer}
              >
                <List>
                  {menuItems.map((item) => (
                    <ListItem
                      button
                      key={item.title}
                      component={Link}
                      to={item.link}
                    >
                      <ListItemText primary={item.title} />
                    </ListItem>
                  ))}
                </List>
              </div>
            </Drawer>
          </>
        ) : (
          <Container style={{ display: "flex" }}>
            <Logo />
            <>
              {menuItems.map((item) => {
                return (
                  <Button
                    size="large"
                    key={item.title}
                    color="inherit"
                    className={classes.menuButton}
                    component={Link}
                    to={item.link}
                  >
                    {item.title}
                  </Button>
                )
              })}
            </>
          </Container>
        )}
      </Toolbar>
    </AppBar>
  )
}

export default Header
