/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"

interface SEOProps {
  description?: string
  title: string
}

const SEO: React.FC<SEOProps> = ({ description = "", title, children }) => {
  const { site, ogImage, logo } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
        ogImage: file(relativePath: { eq: "the_institute_og_logo.png" }) {
          childImageSharp {
            fixed(width: 1200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        logo: file(relativePath: { eq: "the_institute_logo.png" }) {
          childImageSharp {
            fixed(width: 500) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  const ogTitle = `${title} | ${site.siteMetadata.title}`

  const image = `${site.siteMetadata.siteUrl}${ogImage.childImageSharp.fixed.src}`

  const raLogo = `${site.siteMetadata.siteUrl}${logo.childImageSharp.fixed.src}`

  return (
    <Helmet titleTemplate={`%s | ${site.siteMetadata.title}`}>
      <html lang="en" />
      <title lang="en">{title.substring(0, 34)}</title>
      <meta name="description" content={metaDescription} />
      <meta name="og:title" content={ogTitle} />
      <meta name="og:description" content={metaDescription} />
      <meta name="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta name="og:twitter:creator" content={site.siteMetadata.author} />
      <meta name="twitter:description" content={metaDescription} />
      {image && <meta name="og:image" content={image} />}
      {image && <meta name="og:image:width" content="1200" />}
      {image && <meta name="og:image:height" content="630" />}
      {image && <meta name="twitter:card" content="summary_large_image" />}
      {!image && <meta name="twitter:card" content="summary" />}
      <meta
        name="ahrefs-site-verification"
        content="d11e2004c70fc42753bb5be1fa7ce750e1583bb1dd1faa7b82ae5879f344d773"
      ></meta>
      <script type="application/ld+json">{`
      {
        "@context": "http://www.schema.org",
        "@type": "EducationalOrganization",
        "name": "The Institute of Beauty",
        "url": "https://theinstituteofbeauty.com",
        "logo": "${raLogo}",
        "description": "The Institute of Beauty offers a wide range of ITEC and CIBTAC professional qualifications in the areas of Beauty Therapy, Massage and Complementary Therapies.",
        "address": {
           "@type": "PostalAddress",
           "streetAddress": "St. Catherines Hall, Catherine St",
           "addressLocality": "Waterford",
           "postalCode": "X91 T322",
           "addressCountry": "Ireland"
        },
        "geo": {
           "@type": "GeoCoordinates",
           "latitude": "52.257150",
           "longitude": "-7.106950"
        },
        "hasMap": "https://goo.gl/maps/R8QKjRSFBxj4Kn5k6",
         "openingHours": "Mo 09:30-22:00 Tu 09:30-22:00 We 09:30-22:00 Th 09:30-17:00 Sa 09:30-16:00"
      }`}</script>
      {children}
    </Helmet>
  )
}

export default SEO
