/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import "./layout.css"

import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import {
  createMuiTheme,
  createStyles,
  makeStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import ContactPhoneIcon from "@material-ui/icons/ContactPhone"
import EmailIcon from "@material-ui/icons/Email"
import FacebookIcon from "@material-ui/icons/Facebook"
import HomeWork from "@material-ui/icons/HomeWork"
import InstagramIcon from "@material-ui/icons/Instagram"
import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"

import Header, { Logo } from "./header"

const theme = createMuiTheme({
  typography: {
    fontFamily: ["Fira Sans", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#7f8fa6",
    },
    text: {
      primary: "#222222",
    },
  },
})

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    emailText: {
      fontSize: "0.8rem",
    },
  })
)

interface Props {
  children: React.ReactNode
}

const Layout = ({ children }: Props) => {
  const classes = useStyles()
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"))
  const isTablet = useMediaQuery(theme.breakpoints.down("sm"))
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <MuiThemeProvider theme={theme}>
        <Header siteTitle={data.site.siteMetadata.title} />
        <Grid container={true} className={classes.root}>
          <Grid item xs={12}>
            <main>{children}</main>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              borderTop: "1px solid #D2CFD8",
              paddingTop: "52px",
              paddingBottom: "52px",
              marginTop: "16px",
            }}
          >
            <footer>
              <Container>
                <Grid container>
                  <Grid
                    item
                    md={3}
                    xs={12}
                    style={{
                      display: "flex",
                      textAlign: "center",
                      alignItems: "center",
                      padding: "0 16px",
                      flexDirection: "column",
                    }}
                  >
                    <Logo />
                    <p style={{ marginTop: "16px" }}>
                      Where excellence is standard.
                    </p>
                    <p style={{ display: "flex" }}>
                      <ContactPhoneIcon style={{ marginRight: "8px" }} />
                      <a href="tel:+35351877753">(051) 877 753</a> /{" "}
                      <a href="tel:+353876860382">(087) 686 0382</a>
                    </p>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    sm={4}
                    xs={12}
                    style={isTablet ? {} : { borderLeft: "1px solid #E6E4EA" }}
                  >
                    <List>
                      <a href="mailto: theinstituteofbeauty@gmail.com">
                        <ListItem button dense>
                          <ListItemIcon>
                            <EmailIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={"theinstituteofbeauty@gmail.com"}
                          />
                        </ListItem>
                      </a>
                      <a
                        href="https://theclinicofbeauty.ie/"
                        // eslint-disable-next-line react/jsx-no-target-blank
                        target="_blank"
                        rel="noopener"
                      >
                        <ListItem button>
                          <ListItemIcon>
                            <HomeWork />
                          </ListItemIcon>
                          <ListItemText primary="The Clinic Beauty Salon" />
                        </ListItem>
                      </a>
                      <a
                        href="https://www.facebook.com/TheInstituteBeautyWaterford"
                        // eslint-disable-next-line react/jsx-no-target-blank
                        target="_blank"
                        rel="noopener"
                      >
                        <ListItem button>
                          <ListItemIcon>
                            <FacebookIcon />
                          </ListItemIcon>
                          <ListItemText primary="Facebook" />
                        </ListItem>
                      </a>
                      <a
                        href="https://www.instagram.com/theinstituteofbeauty"
                        // eslint-disable-next-line react/jsx-no-target-blank
                        target="_blank"
                        rel="noopener"
                      >
                        <ListItem button>
                          <ListItemIcon>
                            <InstagramIcon />
                          </ListItemIcon>
                          <ListItemText primary="Instagram" />
                        </ListItem>
                      </a>
                    </List>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    sm={4}
                    xs={6}
                    style={isMobile ? {} : { borderLeft: "1px solid #E6E4EA" }}
                  >
                    <List>
                      {[
                        { title: "About Us", url: "/about-us/" },
                        { title: "Courses", url: "/courses/" },
                        { title: "Enrol", url: "/enrol/" },
                      ].map((text) => (
                        <ListItem
                          button
                          key={text.title}
                          style={{ paddingLeft: 40 }}
                          component={Link}
                          to={text.url}
                        >
                          <ListItemText primary={text.title} />
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    sm={4}
                    xs={6}
                    style={{
                      borderLeft: "1px solid #E6E4EA",
                    }}
                  >
                    <List>
                      {[
                        { title: "Contact Us", url: "/contact-us/" },
                        { title: "Terms", url: "/terms-of-service/" },
                        { title: "Privacy", url: "/privacy-policy/" },
                      ].map((text) => (
                        <ListItem
                          button
                          key={text.title}
                          style={{ paddingLeft: 40 }}
                          component={Link}
                          to={text.url}
                        >
                          <ListItemText primary={text.title} />
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                </Grid>
              </Container>
            </footer>
          </Grid>
        </Grid>
      </MuiThemeProvider>
    </>
  )
}

export default Layout
